<template>
    <!--eslint-disable-->
    <div
        class="error"
        v-if="error && error.message"
    >
        {{ t('404.1') }}

        <br>

        {{ t('404.2') }}

        <NuxtLink :to="t('404.3.path')">
            {{ t('404.3.label') }}
        </NuxtLink>

        {{ t('404.4') }}

        <NuxtLink :to="t('404.5.path')">
            {{ t('404.5.label') }}
        </NuxtLink>
    </div>
    <!--eslint-enable-->
</template>

<script setup>
defineProps({
    error: {
        type: Object,
        required: false,
        default: null
    }
});

const { t } = useI18n({
    useScope: 'local'
});
</script>

<style lang="less" src="./error.less"></style>

<i18n lang="json">
{
    "nl": {
        "404": {
            "1": "We kunnen de pagina die je zoekt niet vinden.",
            "2": "Probeer het eens via",
            "3": {
                "path": "/zoek",
                "label": "zoeken"
            },
            "4": "of ga terug naar de",
            "5": {
                "path": "/",
                "label": "homepagina"
            }
        }
    },
    "en": {
        "404": {
            "1": "We can't find the page you're looking for.",
            "2": "Try",
            "3": {
                "path": "/en/search",
                "label": "searching"
            },
            "4": "or return to the",
            "5": {
                "path": "/",
                "label": "homepagina"
            }
        }
    }
}
</i18n>
