<template>
    <div class="sf-image-stack">
        <!-- eslint-disable -->
        <div
            v-for="(image, index) in images"
            :key="index"
            class="sf-image-stack__item"
            :data-index="index"
            :data-selected="index === selectedImageIndex"
        >
            <SfLazyImg
                class="sf-image-stack__image"
                :src="image.url || ''"
                :src-tiny="image.url_tiny"
                height="270"
                width="480"
                @mouseover.native.stop="onMouseEnter(index)"
            >
                <template #figcaption>
                    <figcaption class="sf-image-stack__caption sr-only">
                        <slot name="caption" />
                    </figcaption>
                </template>
            </SfLazyImg>
            <!-- eslint-enable -->
            <div class="sf-image-stack__badge">
                <slot
                    name="badge"
                    :image-index="index"
                />
            </div>
        </div>
    </div>
</template>

<script>
import SfLazyImg from '~/patterns/atoms/lazy-img/lazy-img.vue';

export default {
    components: {
        SfLazyImg
    },

    props: {
        images: {
            type: Array,
            required: true
        },

        selectedImageIndex: {
            type: Number,
            default: 0
        }
    },

    emits: ['image-hover'],

    methods: {
        onMouseEnter(index) {
            this.$emit('image-hover', index);
        }
    }
};
</script>

<style lang="less" src="./image-stack.less"></style>
