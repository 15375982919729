<template>
    <div
        class="sf-page-container"
        :data-collapsed="uiStore.isLayoutCollapsed ? 'true' : 'false'"
    >
        <slot />
    </div>
</template>

<script setup>
const uiStore = useUiStore();
</script>

<style lang="less" src="./page-container.less" />
