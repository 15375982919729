<template>
    <SfPageContainer class="sf-newsletter__container">
        <div class="sf-newsletter__wrapper">
            <SfImageStack :images="images">
                <template #caption>
                    <h2>{{ t('title') }}</h2>
                </template>
            </SfImageStack>

            <p class="sf-newsletter__lead">
                {{ t('lead') }}
            </p>

            <SfButton
                element="a"
                class="dn-button--large sf-newsletter__button"
                target="_blank"
                data-ga-category="nieuwsbrief-subscription"
                data-ga-action="subscribe"
                :data-ga-label="route.path"
                :href="newsletterUrl"
            >
                <template #pre>
                    <SfIcon icon="arrow-right" />
                </template>

                <template #default>{{ t('cta.label') }}</template>
            </SfButton>
        </div>
    </SfPageContainer>
</template>

<script setup>
import SfPageContainer from '~/patterns/organisms/page-container/page-container';
import SfIcon from '~/patterns/atoms/icon/icon.vue';
import SfButton from '~/patterns/atoms/button/button.vue';
import SfImageStack from '~/patterns/molecules/image-stack/image-stack.vue';

const images = [
    {
        url: '/newsletter-1.jpg',
        'url_tiny': '/newsletter-1-tiny.jpg',
        alt: 'Bas Aan t werk',
        title: ''
    },
    {
        url: '/newsletter-2.jpg',
        'url_tiny': '/newsletter-2-tiny.jpg',
        alt: 'Bas Aan t werk',
        title: ''
    },
    {
        url: '/newsletter-3.jpg',
        'url_tiny': '/newsletter-3-tiny.jpg',
        alt: 'Bas Aan t werk',
        title: ''
    }
];

const route = useRoute();

const { t, locale } = useI18n({
    useScope: 'local'
});

const newsletterUrl = computed(() => {
    if (locale.value === 'en') {
        return 'https://signup.ymlp.com/xgmqqswqgmgh';
    }

    return 'https://signup.ymlp.com/xgmqqswqgmgb';
});
</script>

<style lang="less" src="./newsletter.less"></style>

<i18n lang="json">
{
    "nl": {
        "title": "blijf gestimuleerd",
        "cta.label": "aanmelden nieuwsbrief",
        "lead": "ontvang nieuws over subsidies, inspirerende projecten en tips voor een goede aanvraag"
    },
    "en": {
        "title": "stay stimulated",
        "cta.label": "sign up to our newsletter",
        "lead": "receive news about grants, inspiring projects and tips for a successful application"
    }
}
</i18n>
