<template>
    <SfBasicTemplate>
        <template #header>
            <SfHero
                v-if="error.statusCode === '404'"
                :title="t('generic')"
            />

            <SfHero
                v-else
                :title="t('404')"
            />
        </template>

        <template #default>
            <SfPageContainer>
                <SfError :error="error" />
            </SfPageContainer>
        </template>
    </SfBasicTemplate>
</template>

<script setup>
import SfBasicTemplate from '~/patterns/templates/basic.vue';
import SfPageContainer from '~/patterns/organisms/page-container/page-container';
import SfError from '~/patterns/molecules/error/error.vue';
import SfHero from '~/patterns/organisms/hero/hero.vue';
import { useNavigationVisible } from '~/composables/useNavigationVisible';

defineProps({
    error: {
        type: Object,
        required: false,
        default: null
    }
});

const { t } = useI18n({
    useScope: 'local'
});

useNavigationVisible();
</script>

<i18n lang="json">
{
    "nl": {
        "generic": "Er is iets fout gegaan",
        "404": "Pagina niet gevonden"
    },
    "en": {
        "generic": "Something went wrong",
        "404": "Page not found"
    }
}
</i18n>
