<template>
    <NuxtLayout>
        <div>
            <NuxtPage />
        </div>
    </NuxtLayout>
</template>

<script setup>
useFavicons();
</script>
