export const useNavigationVisible = () => {
    const uiStore = useUiStore();

    onBeforeMount(() => {
        uiStore.toggleNavigationVisibility(true);
    });

    onActivated(() => {
        uiStore.toggleNavigationVisibility(true);
    });

    return {
        uiStore
    };
};
