export default defineNuxtPlugin(() => {
    const uiStore = useUiStore();
    const modalStore = useModalStore();

    useRouter().afterEach(() => {
        if (uiStore.isMenuOpen) {
            uiStore.closeMenu();
        }

        if (modalStore.activeModal) {
            modalStore.close();
        }
    });
});
