export const useModalStore = defineStore('modal', {
    state: () => ({
        activeModal: false,
        data: null
    }),

    actions: {
        close() {
            this.activeModal = false;
            this.data = null;
        },

        /**
         * Open specific modal
         *
         * The payload can either be a string matching a modal's name,
         * or an object containing a 'name' property. When using
         * the object any data you add is accessible using the
         * 'modal/data' getter in your component.
         *
         * @param   {String|Object}  payload  String or object containing modal name.
         */
        open(payload) {
            // Reset data
            this.data = null;

            // If no modal name is present, reset it.
            if (!payload || !payload.name) {
                this.activeModal = false;
            }

            // If the payload is a string, just interpret that as the modal's name.
            if (typeof payload === 'string') {
                this.setActiveModal(payload);

                return this.activeModal;
            }

            // If it does have a name, open it. And set the payload object
            // to the current modal data, for use in the component iself.
            if (payload.name) {
                this.data = payload;
                this.setActiveModal(payload.name);
            }
        },

        setActiveModal(name) {
            this.activeModal = name;
            document.body.setAttribute('data-modal-open', name ? 'true' : 'false');
        }
    },
});
