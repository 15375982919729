interface State {
    menu: {
        isOpen: boolean
    },
    navigation: {
        isVisible: boolean
    },
    layout: {
        isCollapsed: boolean
    },
    isMotionReduced: boolean;
    scrollPosition: number
}

export const useUiStore = defineStore('ui', {
    state: (): State => ({
        menu: {
            isOpen: false
        },
        navigation: {
            isVisible: false
        },
        layout: {
            isCollapsed: false
        },
        isMotionReduced: false,
        scrollPosition: 0
    }),

    getters: {
        isLayoutCollapsed: state => state.layout.isCollapsed,
        isMenuOpen: state => state.menu.isOpen,
        isNavigationVisible: state => state.navigation.isVisible,
    },

    actions: {
        toggleLayoutCollapse(boolVal?: boolean) {
            if (boolVal !== undefined) {
                this.layout.isCollapsed = boolVal;
            } else {
                this.layout.isCollapsed = !this.isLayoutCollapsed;
            }
        },

        toggleNavigationVisibility(boolVal?: boolean) {
            if (boolVal !== undefined) {
                this.navigation.isVisible = boolVal;
            } else {
                this.navigation.isVisible = !this.isNavigationVisible;
            }
        },

        toggleMenu() {
            this.menu.isOpen = !this.isMenuOpen;

            document.body.setAttribute('data-menu-open', `${this.menu.isOpen}`);
        },

        closeMenu() {
            this.menu.isOpen = false;

            document.body.setAttribute('data-menu-open', 'false');
        },

        openMenu() {
            this.menu.isOpen = true;

            document.body.setAttribute('data-menu-open', 'true');
        },

        updateScrollPosition(scrollPosition: number) {
            this.scrollPosition = scrollPosition;
        },

        setMotionReduced(boolVal: boolean) {
            this.isMotionReduced = boolVal;
        }
    },
});
