<template>
    <figure
        class="lazy-img"
        :data-loaded="loaded ? 'true' : 'false'"
    >
        <div
            :style="{
                paddingBottom: `${ height/width*100 }%`
            }"
            class="lazy-img__wrapper"
        >
            <img
                ref="image"
                :src="src"
                :srcset
                :sizes
                :alt
                :title
                :width
                :height
                :loading
                :decoding
                :fetchpriority
                class="lazy-img__highres"
                @load="loaded = true"
            >

            <div
                :style="{
                    backgroundImage: `url('${ srcTiny }')`
                }"
                class="lazy-img__lowres"
            />
        </div>

        <slot name="figcaption">
            <figcaption v-if="caption">{{ caption }}</figcaption>
        </slot>
    </figure>
</template>

<script>
export default {
    props: {
        src: {
            type: String,
            default: '',
            required: true
        },

        srcset: {
            type: String,
            default: undefined,
            required: false
        },

        sizes: {
            type: String,
            default: undefined,
            required: false
        },

        srcTiny: {
            type: String,
            default: undefined
        },

        title: {
            type: String,
            default: ''
        },

        alt: {
            type: String,
            default(props) {
                return props.title;
            }
        },

        caption: {
            type: String,
            default: ''
        },

        width: {
            type: [String, Number],
            default: undefined,
            required: true
        },

        height: {
            type: [String, Number],
            default: undefined,
            required: true
        },

        loading: {
            type: String,
            default: 'lazy',
            validator: value => ['lazy', 'eager'].includes(value)
        },
        decoding: {
            type: String,
            default: 'auto',
            validator: value => ['sync', 'async', 'auto'].includes(value)
        },
        fetchpriority: {
            type: String,
            default: 'auto',
            validator: value => ['high', 'low', 'auto'].includes(value)
        },
    },

    setup() {
        const image = ref(null);

        return { image };
    },

    data() {
        return {
            loaded: import.meta.server
        };
    },

    mounted() {
        if (this.image.complete) {
            this.loaded = true;
        }
    }
};
</script>

<style src="./lazy-img.less" lang="less"></style>
