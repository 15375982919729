import revive_payload_client_jRtutphRaY from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_glgfzof235o5a65ik2m32m73te/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_XLyMma7Cua from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_glgfzof235o5a65ik2m32m73te/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_5b8EzFaFVk from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_glgfzof235o5a65ik2m32m73te/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_aPoFSSivJg from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@20.16.10_less@4.2_mf2qg5ldwtluggq37dnkqajt4a/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_RW62oQjMyN from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_glgfzof235o5a65ik2m32m73te/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_JP6icPqLi5 from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_glgfzof235o5a65ik2m32m73te/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_UruJhYTYUZ from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_glgfzof235o5a65ik2m32m73te/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_sQluUTxqqe from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_glgfzof235o5a65ik2m32m73te/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_E4JHtm2uoD from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.2_vue@3.5.11_typescript@5.6.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_1wwOL16CfR from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_glgfzof235o5a65ik2m32m73te/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_client_Y9xNDGqTG4 from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/@vite-pwa+nuxt@0.5.0_@vite-pwa+assets-generator@0.2.6_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_oc3d56vkpfaowm57u462a4qioa/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_8kl6hgpGON from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/@nuxtjs+algolia@1.10.2_@vue+server-renderer@3.5.11_vue@3.5.11_typescript@5.6.2___magicast@0.3_dejcwsv3asbsi67myqytnngplm/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import i18n_gx3Z3U4suS from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.1.1_magicast@0.3.5_rollup@4.24.0_vue@3.5.11_typescript@5.6.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_6CPTQUYGR9 from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/@digitalnatives+graphql-client@4.1.1_graphql@16.9.0_magicast@0.3.5_nuxt@3.13.2_@parcel+watche_2jaojx3g6yc66kf2qqusadjafu/node_modules/@digitalnatives/graphql-client/dist/runtime/plugin.mjs";
import plugin_g4Z5dgbZVP from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.24.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import gtm_cXsUZobmSF from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/plugins/gtm.ts";
import content_placeholders_SU8YstkPji from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/plugins/content-placeholders.js";
import router_WMnTGednOQ from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/plugins/router.ts";
import v_clipboard_client_X8J8B0mEuA from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/plugins/v-clipboard.client.js";
export default [
  revive_payload_client_jRtutphRaY,
  unhead_XLyMma7Cua,
  router_5b8EzFaFVk,
  _0_siteConfig_aPoFSSivJg,
  payload_client_RW62oQjMyN,
  navigation_repaint_client_JP6icPqLi5,
  check_outdated_build_client_UruJhYTYUZ,
  chunk_reload_client_sQluUTxqqe,
  plugin_vue3_E4JHtm2uoD,
  components_plugin_KR1HBZs4kY,
  prefetch_client_1wwOL16CfR,
  pwa_client_Y9xNDGqTG4,
  plugin_8kl6hgpGON,
  i18n_gx3Z3U4suS,
  plugin_6CPTQUYGR9,
  plugin_g4Z5dgbZVP,
  gtm_cXsUZobmSF,
  content_placeholders_SU8YstkPji,
  router_WMnTGednOQ,
  v_clipboard_client_X8J8B0mEuA
]