<template>
    <div
        class="sf-hero"
        :data-collapsed="isCollapsed ? 'true' : 'false'"
        :data-has-image="!!randomImage && randomImage.url_sm ? 'true' : 'false'"
    >
        <div
            v-if="randomImage && randomImage.url_sm"
            class="sf-hero__image"
        >
            <LazyImg
                v-if="randomImage.url"
                class="lazy-img--cover"
                :src="randomImage.url_sm"
                :src-tiny="randomImage.url_tiny"
                :srcset="`
                    ${randomImage.url_xs} 320w,
                    ${randomImage.url_sm} 600w,
                    ${randomImage.url_md} 800w,
                    ${randomImage.url_lg} 1440w,
                    ${randomImage.url} 1920w
                `"
                sizes="
                    (max-width: 380px) 320px,
                    100vw
                "
                :width="randomImage.width"
                :height="randomImage.height"
                loading="eager"
                fetchpriority="high"
            />
            <div
                v-if="!!$slots.stickers"
                class="sf-hero__stickers"
            >
                <slot name="stickers" />
            </div>
        </div>

        <div class="sf-hero__body">
            <SfPageContainer>
                <!-- eslint-disable vue/no-v-html -->
                <h1
                    v-if="title"
                    class="sf-hero__title"
                    v-html="title"
                />
                <!-- eslint-enable vue/no-v-html -->
            </SfPageContainer>
        </div>
    </div>
</template>

<script setup>
import SfPageContainer from '~/patterns/organisms/page-container/page-container';
import LazyImg from '~/patterns/atoms/lazy-img/lazy-img';

const props = defineProps({
    title: {
        type: String,
        default: null
    },
    images: {
        type: Array,
        default: null
    }
});

const { images } = toRefs(props);

const uiStore = useUiStore();
const { isLayoutCollapsed: isCollapsed } = storeToRefs(uiStore);

const randomImage = useRandomHydrated(images);
</script>

<style lang="less" src="./hero.less" />
