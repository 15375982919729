<template>
    <div class="template">
        <div class="template__header">
            <slot name="header" />
        </div>

        <div
            id="body"
            tabindex="-1"
            class="template__body"
            dark-theme-allowed="forced"
        >
            <slot />
        </div>

        <div
            v-if="!!$slots.aside"
            end-dark-theme
        >
            <slot name="aside" />
        </div>

        <div
            v-if="!!$slots.related"
            class="template__related"
            end-dark-theme
        >
            <slot name="related" />
        </div>

        <div
            class="template__footer"
            dark-theme-ended="true"
        >
            <SfNewsletter />
        </div>

        <slot name="modals" />
    </div>
</template>

<script>
import SfNewsletter from '~/patterns/organisms/newsletter/newsletter';

export default {
    components: {
        SfNewsletter
    }
};
</script>
